import { getMyAbility } from "@/libs/acl/utils";
import moment from "moment";
import socket from "socket.io-client";
import service from "@/services";
import { $themeConfig } from "@themeConfig";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  component: {
    ToastificationContent,
  },
  computed: {
    currentTheme() {
      return $themeConfig.layout.skin;
    },
    JENIS_AKUN() {
      return {
        debit: 1,
        kredit: 2,
        debit_kredit: 3,
      };
    },
    //MYBISNIS
    isCabang() {
      // const CABANG_KEYS = ["cabang"];
      if (this.user && this.user.level) {
        const levelKey = this.user.level.nama_level
          .split(" ")
          .map((word) => word.toLowerCase())
          .join("_");
        return this.CABANG_KEYS.includes(levelKey);
      }
      return false;
    },
    isMarketing() {
      // const MKT_KEY = ["marketing"];
      if (this.user && this.user.level) {
        const levelKey = this.user.level.nama_level
          .split(" ")
          .map((word) => word.toLowerCase())
          .join("_");
        return this.MKT_KEYS.includes(levelKey);
      }

      return false;
    },
    isGM() {
      const SPV_KEYS = ["general manager", "gm", "manager", "general_manager"];
      if (this.user && this.user.level) {
        const levelKey = this.user.level.nama_level
          .split(" ")
          .map((word) => word.toLowerCase())
          .join("_");
        return SPV_KEYS.includes(levelKey);
      }

      return false;
    },
    isOwner() {
      const OWNER_KEY = ["owner", "Owner"];
      if (this.user && this.user.level) {
        const levelKey = this.user.level.nama_level
          .split(" ")
          .map((word) => word.toLowerCase())
          .join("_");
        return OWNER_KEY.includes(levelKey);
      }

      return false;
    },
    isFinance() {
      const SPV_KEYS = ["finance"];
      if (this.user && this.user.level) {
        const levelKey = this.user.level.nama_level
          .split(" ")
          .map((word) => word.toLowerCase())
          .join("_");
        return SPV_KEYS.includes(levelKey);
      }

      return false;
    },
    isCabang() {
      const CABANG_KEYS = ["cabang"];
      if (this.user && this.user.level) {
        const levelKey = this.user.level.nama_level
          .split(" ")
          .map((word) => word.toLowerCase())
          .join("_");
        return CABANG_KEYS.includes(levelKey);
      }

      return false;
    },
    isMarketing() {
      const MKT_KEYS = ["marketing"];
      if (this.user && this.user.level) {
        const levelKey = this.user.level.nama_level
          .split(" ")
          .map((word) => word.toLowerCase())
          .join("_");
        return MKT_KEYS.includes(levelKey);
      }

      return false;
    },
    isSPV() {
      const SPV_KEYS = ["spv", "supervisor"];
      if (this.user && this.user.level) {
        const levelKey = this.user.level.nama_level
          .split(" ")
          .map((word) => word.toLowerCase())
          .join("_");
        return SPV_KEYS.includes(levelKey);
      }

      return false;
    },
    isSales() {
      const SALES_KEY = "sales";
      if (this.user && this.user.level) {
        return this.user.level.nama_level.toLowerCase() === SALES_KEY;
      }
      return false;
    },
    isAdmin() {
      const ADMIN_KEY = "admin";
      return this.user?.level?.nama_level.toLowerCase() === ADMIN_KEY;
    },
    isAdminAsset() {
      const KEYS = ["admin_asset", "admin_aset"];
      if (this.user && this.user.level) {
        const levelKey = this.user.level.nama_level
          .split(" ")
          .map((word) => word.toLowerCase())
          .join("_");
        return KEYS.includes(levelKey);
      }
      return false;
    },
    isAdminGudang() {
      if (this.user && this.user.level) {
        const levelKey = this.user.level.nama_level
          .split(" ")
          .map((word) => word.toLowerCase())
          .join("_");
        return this.GUDANG_KEYS.includes(levelKey);
      }

      return false;
    },
    myGudang() {
      if (localStorage.myGudang) {
        return JSON.parse(localStorage.myGudang);
      }

      return null;
    },
    gudangName() {
      if (localStorage.myGudang) {
        const gudang = JSON.parse(localStorage.myGudang);
        this.gudang_id = gudang.id;
        return gudang.nama_gudang;
      }

      return null;
    },
    GUDANG_KEYS() {
      return ["admin_gudang", "gudang", "sales", "admin_asset", "admin_aset"];
      // return ["admin_gudang", "gudang", "sales", "admin_asset", "admin_aset", "cabang"];
    },
    CABANG_KEYS() {
      return ["cabang"];
    },
    MKT_KEYS() {
      return ["marketing"];
    },
    user() {
      if (localStorage.userData) {
        return JSON.parse(localStorage.userData);
      }

      return null;
    },
    moduleName() {
      //
      const menus = JSON.parse(localStorage.accessMenu);
      let check;
      if (this.$route.path == "/") {
        // get route redirect
        const { routes } = this.$router.options;
        const rootRoute = routes.find((item) => item.path == "/");
        if (!rootRoute) return false;

        const redirectRoute = rootRoute.redirect.name;
        check = routes.find((item) => item.name == redirectRoute);
        return check ? check.name : null;
      }

      let title = null;
      let submenus = [];
      menus.map((menu) => {
        if (menu.children) {
          submenus = [...submenus, ...menu.children];
        }
      });

      let menu = menus.find((item) => item.url == this.$route.path);
      if (!menu && this.$route.meta.parent) menu = menus.find((item) => item.url == this.$route.meta.parent);
      if (!menu) menu = submenus.find((item) => item.url == this.$route.path);

      if (menu) {
        if (menu.children) {
          const findChild = menu.children.find((child) => child.url == this.$route.path);

          title = findChild ? findChild.title : null;
        } else {
          title = menu.title;
        }
      }

      return title;
    },
  },
  methods: {
    refreshToken() {
      this.$store.dispatch("refresh/refresh").then((res) => {});
    },
    async clearExportedFile(filename) {
      try {
        const config = {
          url: "/clear-export",
          method: "get",
          params: {
            filename,
          },
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        };
        const response = await service.sendRequest(config);

        return Promise.resolve(response.data);
      } catch (e) {
        if (e.response && e.response.data) {
          const blob = e.response.data;
          const text = await blob.text();
          const errorJson = JSON.parse(text);

          return Promise.reject(errorJson);
        }
        return Promise.reject(e);
      }
    },
    eventSocket(key, value) {
      // key : ajuan_barang, ajuan_konsumen, ajuan_bop
      // value: {
      //     level : level_user,
      //     data: response_pos
      // }
      // const io = socket("http://notif.mybisnis.online", {
      const io = socket("http://notif.armezza.com", {
        autoConnect: false,
        transports: ["websocket"],
      });
      io.connect();
      io.on("connection", (client) => {
        console.log("Connection On");
        client.on("disconnect", () => console.log("user disconnect"));
      });

      io.emit(key, value);
    },
    apiFileAsli() {
      return "https://api.mybisnis.online/";
    },
    capitalize(s) {
      if (typeof s !== "string") return "";
      return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
    },
    async customRejectPromise(e) {
      if (e.response && e.response.data) {
        const error = e.response.data;
        if (typeof error === "object") {
          return Promise.reject(error);
        }

        const blob = e.response.data;
        const text = await blob.text();
        const errorJson = JSON.parse(text);

        return Promise.reject(errorJson);
      }

      return Promise.reject(e);
    },
    humanDate(dateString) {
      const date = new Date(dateString);
      return moment(date).locale("id").format("DD-MM-YYYY");
    },
    humanDateTime(dateString) {
      const date = new Date(dateString);
      return moment(date).locale("id").format("DD-MM-YYYY HH:mm");
    },
    async currentMenu(path) {
      const currentRoute = path || this.$route.path;
      const accessMenu = JSON.parse(localStorage.accessMenu);

      let currentMenu = accessMenu.find((menu) => {
        if (menu.children) {
          return menu.children.find((child) => child.url == currentRoute);
        }
        return menu.url == currentRoute;
      });

      if (currentMenu && currentMenu.children) {
        currentMenu = currentMenu.children.find((menu) => menu.url == currentRoute);
      }

      const menus = await this.$store.dispatch("menu/getData");
      if (!menus) {
        return null;
      }
      if (!currentMenu) {
        const menus = await this.$store.dispatch("menu/getData", {
          search: path,
        });
        currentMenu = menus.find((menu) => menu.url == path);
      }
      const thereMenu = menus.find((menu) => menu.url == currentRoute);
      if (!thereMenu) return null;
      currentMenu.id = thereMenu.id;
      return currentMenu;
    },
    getShortName(words) {
      return words
        .split(" ")
        .map((word) => word.toLowerCase())
        .join("_");
    },
    unFormatRupiah(angka) {
      if (typeof angka === "number") {
        return angka;
      }
      if (angka) {
        return parseInt(angka.split(".").join(""));
      }

      return "";
    },
    formatRupiah(angka, prefix = undefined) {
      let isMinus = "";
      if (parseInt(angka) < 0) {
        isMinus = "-";
      }
      if (angka) {
        const number_string = angka
          .toString()
          .replace(/[^,\d]/g, "")
          .toString();
        const split = number_string.split(",");
        const sisa = split[0].length % 3;
        let rupiah = split[0].substr(0, sisa);
        const ribuan = split[0].substr(sisa).match(/\d{3}/gi);
        let separator = "";

        // tambahkan titik jika yang di input sudah menjadi angka ribuan
        if (ribuan) {
          separator = sisa ? "." : "";
          rupiah += separator + ribuan.join(".");
        }

        rupiah = split[1] != undefined ? `${rupiah},${split[1]}` : rupiah;
        rupiah = `${isMinus}${rupiah}`;
        return prefix == undefined ? rupiah : rupiah ? `Rp. ${rupiah}` : "";
      }

      return angka;
    },
    formatRupiahAkun(angka, prefix = undefined) {
      let isMinus = false;
      if (parseInt(angka) < 0) {
        isMinus = true;
      }
      if (angka) {
        const number_string = angka
          .toString()
          .replace(/[^,\d]/g, "")
          .toString();
        const split = number_string.split(",");
        const sisa = split[0].length % 3;
        let rupiah = split[0].substr(0, sisa);
        const ribuan = split[0].substr(sisa).match(/\d{3}/gi);
        let separator = "";

        // tambahkan titik jika yang di input sudah menjadi angka ribuan
        if (ribuan) {
          separator = sisa ? "." : "";
          rupiah += separator + ribuan.join(".");
        }

        rupiah = split[1] != undefined ? `${rupiah},${split[1]}` : rupiah;
        if (isMinus) {
          rupiah = `(${rupiah})`;
        }
        return prefix == undefined ? rupiah : rupiah ? `Rp. ${rupiah}` : "";
      }

      return angka;
    },
    updateAbility(userRoles) {
      const myAbilities = getMyAbility(userRoles);
      this.$ability.update(myAbilities);
    },
    allowCreate(moduleName) {
      if (!moduleName) moduleName = this.moduleName;
      return this.$can("create", moduleName);
    },
    allowUpdate(moduleName) {
      if (!moduleName) moduleName = this.moduleName;
      return this.$can("update", moduleName);
    },
    allowDelete(moduleName) {
      if (!moduleName) moduleName = this.moduleName;
      return this.$can("delete", moduleName);
    },
    truncateString(str, num) {
      if (str) {
        if (str.length && str.length <= num) {
          return str;
        }

        return `${str.slice(0, num)}...`;
      }

      return str;
    },
    displaySuccess(data) {
      if (data) {
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: "Berhasil!",
              icon: "CheckIcon",
              variant: "success",
              text: data.text ? data.text : data.message,
            },
          },
          {
            position: "bottom-right",
          }
        );
      }
    },
    displayWarning(data) {
      if (data) {
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: data.title,
              icon: "AlertCircleIcon",
              variant: "warning",
              text: data.text,
            },
          },
          {
            position: "bottom-right",
          }
        );
      }
    },
    displayInfo(data) {
      if (data) {
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: data.title,
              icon: "AlertCircleIcon",
              variant: "info",
              text: data.text,
            },
          },
          {
            position: "bottom-right",
          }
        );
      }
    },
    getCurrentDate() {
      const date = new Date();
      const getdate = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
      const getMonth = date.getMonth() < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
      return `${date.getFullYear()}-${getMonth}-${getdate}`;
    },
    getBulan() {
      const d = new Date();
      if (d.getMonth() < 10) {
        return `0${d.getMonth() + 1}`;
      }
      return `${d.getMonth() + 1}`;
    },
    getTahun() {
      const d = new Date();
      return `${d.getFullYear()}`;
    },
    displayError(e) {
      console.error(e);
      if (e.response) {
        const error = e.response.data;
        console.error("error", error);
        if (!error.message) {
          Object.keys(error).map((key) => {
            const message = typeof error[key] === "object" ? error[key].join(", ") : error[key];
            this.$bvToast.toast(message, {
              title: "Peringatan",
              variant: "danger",
              solid: true,
              toaster: "b-toaster-bottom-right",
            });
          });
        } else {
          this.$bvToast.toast(error.message, {
            title: "Peringatan",
            variant: "danger",
            solid: true,
            toaster: "b-toaster-bottom-right",
          });
        }
      } else if (e.message) {
        this.$bvToast.toast(e.message, {
          title: "Peringatan",
          variant: "danger",
          solid: true,
          toaster: "b-toaster-bottom-right",
        });
      } else if (typeof e === "object") {
        e.map((message) => {
          this.$bvToast.toast(message, {
            title: "Peringatan",
            variant: "danger",
            solid: true,
            toaster: "b-toaster-bottom-right",
          });
        });
      } else {
        console.error(e);
      }
    },
  },
};
