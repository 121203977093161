import Vue from "vue";
import Vuex from "vuex";

// Modules
import service from "@/services";
import app from "./app";
import refresh from "./refresh/index";
import rute from "./rute/index";
import appConfig from "./app-config";
import verticalMenu from "./vertical-menu";
import jabatan from "./jabatan/index";
import barang from "./barang/index";
import level from "./level/index";
import akun from "./akun/index";
import akun_kategori from "./akun-kategori/index";
import menu from "./menu/index";
import role from "./role/index";
import karyawan from "./karyawan/index";
import pengguna from "./pengguna/index";
import teritori from "./teritori/index";
import kategori from "./kategori/index";
import satuan from "./satuan/index";
import gudang from "./cabang/index";
import pengembalianbarang from "./pengembalianbarang/index";
import blok from "./blok/index";
import rak from "./rak/index";
import laci from "./laci/index";
import kendaraan from "./kendaraan/index";
import statusrole from "./statusrole/index";
import bagian from "./bagian/index";
import ceklist from "./ceklist/index";
import disposalrusak from "./disposal-rusak/index";
import disposalhilang from "./disposal-hilang/index";
import kategoriHarga from "./kategori-harga/index";
import penerimaan from "./penerimaan-barang";
import requestbarang from "./request-barang";
import requestbarangrincian from "./request-barang-rincian";
import stokopname from "./stokopname";
import penyimpanan from "./penyimpanan";
import perbaikan from "./perbaikan";
import ajuankonsumen from "./ajuan-konsumen";
import tipekonsumen from "./tipe-konsumen";
import analisis from "./analisis";
import jurnal from "./jurnal";
import penggajian from "./penggajian";
import mutasi from "./mutasi";
import penjualan from "./penjualan";
import kas from "./kas";
import pallet from "./pallet";
import konversi from "./konversi";
import sudahsetor from "./sudah-setor";
import belumsetor from "./belum-setor";
import kas_alur from "./kas-alur";
import biaya_pengambilan from "./biaya-pengambilan";
import biaya_operasional from "./biaya-operasional";
import biaya_entertain from "./biaya-enter";
import barang_entertain from "./barang-enter";
import neraca from "./neraca";
import biaya_pajak from "./biaya-pajak";
import returKonsumen from "./retur-konsumen/index";
import { setDefaultModule } from "./default";
import bop from "./bop/index";
import asset from "./asset/index";
import kategoriAsset from "./kategori-asset/index";
import setoran from "./setoran/index";
import pembelian from "./pembelian/index";
import laporan from "./laporan/index";
import laporanasset from "./laporanasset/index";
import dashboard from "./dashboard/index";
import notifikasi from "./notifikasi/index";
import returSupplier from "./retur-supplier/index";
import apk from "./apk-versioning/index";
import setting from "./setting/index";
import wilayah from "./wilayah/index";
//HR
import member from "./hr/member/index";
//Cabang
import cabang from "./cabang/index";
import tokoblok from "./affiliate/blok";
import tokopalet from "./affiliate/palet";
import tokorak from "./affiliate/rak";
import tokolaci from "./affiliate/laci";
import tokopenyimpanan from "./affiliate/penyimpanan";
import tokopenyimpanansementara from "./affiliate/penyimpanan_sementara";
import tokopenempatan from "./affiliate/penempatan";
import jenispenyakit from "./jenis-penyakit/index";
import kelompok from "./kelompok/index";
import layananekspedisi from "./layanan-ekspedisi/index";
import pembeliancabang from "./pembelian-cabang/index";
import hargajual from "./harga-jual/index";
//Gudang
import pengiriman from "./affiliate/pengiriman";
import kasir from "./affiliate/kasir";
import tokoreturpusat from "./affiliate/returpusat";
import tokostokopname from "./affiliate/stokopname";
import tokodisposalhilang from "./affiliate/disposalhilang";
import tokodisposalrusak from "./affiliate/disposalrusak";
import signa from "./affiliate/signa";
import ket_resep from "./affiliate/ket_resep";
import dokter from "./dokter/index";
import reward from "./reward/index";
import transaksiOnline from "./transaksi-online/index";
import komisi from "./komisi/index";
import poin from "./poin/index";
import rekening from "./rekening/index";
import ajuan from "./ajuan/index";
import ajuanrekap from "./ajuan/rekap";

const notaBop = setDefaultModule(service, {
  url: "/bop-laporan",
  payload: {
    params: {},
  },
});
const konsumen = setDefaultModule(service, {
  url: "/konsumen",
  payload: {
    params: {},
  },
});
const hargaBarang = setDefaultModule(service, {
  url: "/harga-jual",
  payload: {
    params: {},
  },
});

const returKonsumenRincian = setDefaultModule(service, {
  url: "/retur-konsumen-rincian",
  payload: {
    params: {},
  },
});

const supplier = setDefaultModule(service, {
  url: "/supplier",
  payload: { params: {} },
});

const pembelianRincian = setDefaultModule(service, {
  url: "/pembelian-rincian",
  payload: { params: {} },
});

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    app,
    refresh,
    rute,
    "apk-versioning": apk,
    notifikasi,
    setting,
    laporan,
    laporanasset,
    setoran,
    asset,
    pembeliancabang,
    "retur-supplier": returSupplier,
    "kategori-asset": kategoriAsset,
    supplier,
    pembelian,
    "pembelian-rincian": pembelianRincian,
    "retur-konsumen": returKonsumen,
    "retur-konsumen-rincian": returKonsumenRincian,
    pengembalianbarang,
    bop,
    "harga-barang": hargaBarang,
    konsumen,
    "nota-bop": notaBop,
    kas,
    pallet,
    stokopname,
    penggajian,
    kas_alur,
    mutasi,
    penyimpanan,
    perbaikan,
    sudahsetor,
    belumsetor,
    // konsumen,
    "kategori-harga": kategoriHarga,
    "penerimaan-barang": penerimaan,
    barang,
    analisis,
    requestbarang,
    requestbarangrincian,
    karyawan,
    ajuankonsumen,
    tipekonsumen,
    pengguna,
    akun_kategori,
    akun,
    jurnal,
    menu,
    biaya_pengambilan,
    biaya_operasional,
    biaya_entertain,
    biaya_pajak,
    neraca,
    barang_entertain,
    level,
    role,
    konversi,
    jabatan,
    penjualan,
    appConfig,
    verticalMenu,
    kategori,
    satuan,
    teritori,
    gudang,
    blok,
    rak,
    laci,
    kendaraan,
    statusrole,
    bagian,
    ceklist,
    disposalrusak,
    disposalhilang,
    dashboard,
    wilayah,
    //HR
    member,
    //Cabang
    cabang,
    tokoblok,
    tokopalet,
    tokorak,
    tokolaci,
    jenispenyakit,
    layananekspedisi,
    hargajual,
    tokopenyimpanan,
    tokopenyimpanansementara,
    tokopenempatan,
    //Gudang
    pengiriman,
    kasir,
    kelompok,
    tokopenempatan,
    tokopenempatan,
    tokoreturpusat,
    tokostokopname,
    tokodisposalhilang,
    tokodisposalrusak,
    signa,
    ket_resep,
    dokter,
    reward,
    transaksiOnline,
    komisi,
    poin,
    rekening,
    ajuan,
    ajuanrekap,
  },
  strict: process.env.DEV,
});
