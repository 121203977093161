import service from "@/services";

export default {
  namespaced: true,
  state: {
    datas: [],
    totals: 0,
    records: null,
  },
  getters: {
    getKomisi: (state) => (id) => state.datas.find((item) => item.id == id),
  },
  mutations: {
    SET_DATA(state, data) {
      state.datas = data;
    },
    SET_TOTAL(state, total) {
      state.totals = total;
    },
  },
  actions: {
    async getDataById({}, id) {
      try {
        const config = {
          url: `/komisi/index-barang/${id}`,
          method: "get",
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        };
        const response = await service.sendRequest(config);

        return Promise.resolve(response.data);
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async getData({ commit }, params = {}) {
      try {
        const config = {
          url: "/komisi/index-barang",
          method: "get",
          params,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        };
        const response = await service.sendRequest(config);

        const result = response.data && response.data.data ? response.data.data : [];
        const result_total = response.data.total;
        commit("SET_DATA", result);
        commit("SET_TOTAL", result_total);

        return Promise.resolve(result);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async getDataJenis({ commit }, params = {}) {
      try {
        const config = {
          url: "/komisi/index-jenis",
          method: "get",
          params,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        };
        const response = await service.sendRequest(config);

        const result = response.data && response.data.data ? response.data.data : [];

        commit("SET_DATA", result);

        return Promise.resolve(result);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async getTargetKomisi({ commit }, params = {}) {
      try {
        const config = {
          url: "/komisi-bln/get-target",
          method: "get",
          params,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        };
        const response = await service.sendRequest(config);

        const result = response.data && response.data ? response.data : [];

        commit("SET_DATA", result);

        return Promise.resolve(result);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async save({}, payload = {}) {
      try {
        const config = {
          url: "/komisi/input-barang",
          method: "post",
          data: payload,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        };

        const response = await service.sendRequest(config);

        return Promise.resolve(response.data);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async saveJenis({}, payload = {}) {
      try {
        const config = {
          url: "/komisi/input-jenis",
          method: "post",
          data: payload,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        };

        const response = await service.sendRequest(config);

        return Promise.resolve(response.data);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async saveTargetKomisi({}, payload = {}) {
      try {
        const config = {
          url: "/komisi-bln/set-target",
          method: "post",
          data: payload,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        };

        const response = await service.sendRequest(config);

        return Promise.resolve(response.data);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async listKomisiById({}, id) {
      try {
        const config = {
          url: `/komisi/index/${id}`,
          method: "get",
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        };
        const response = await service.sendRequest(config);

        return Promise.resolve(response.data);
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async listKomisi({ commit }, params = {}) {
      try {
        const config = {
          url: "/komisi/index",
          method: "get",
          params,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        };
        const response = await service.sendRequest(config);

        const result = response.data && response.data.data ? response.data.data : [];

        return Promise.resolve(result);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async rekapKomisi({ commit }, params = {}) {
      try {
        const config = {
          url: "/komisi/index-rekap",
          method: "get",
          params,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        };
        const response = await service.sendRequest(config);

        const result = response.data && response.data ? response.data : [];
        const result_total = response.data.total;

        return Promise.resolve(result);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async listKomisiBulanan({ commit }, params = {}) {
      try {
        const config = {
          url: "/komisi-bln/list-transaksi",
          method: "get",
          params,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        };
        const response = await service.sendRequest(config);

        const result = response.data && response.data.data ? response.data.data : [];

        return Promise.resolve(result);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async rekapKomisiBulanan({ commit }, params = {}) {
      try {
        const config = {
          url: "/komisi-bln/rekap",
          method: "get",
          params,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        };
        const response = await service.sendRequest(config);

        const result = response.data && response.data ? response.data : [];
        const result_total = response.data.total;

        return Promise.resolve(result);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async rekapProgresKomisiBulanan({ commit }, params = {}) {
      try {
        const config = {
          url: "/komisi-bln/progress-barang",
          method: "get",
          params,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        };
        const response = await service.sendRequest(config);

        const result = response.data && response.data ? response.data : [];
        const result_total = response.data.total;

        return Promise.resolve(result);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async rekapProgresBarangKomisiBulanan({ commit }, params = {}) {
      try {
        const config = {
          url: "/komisi-bln/progress-barang",
          method: "get",
          params,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        };
        const response = await service.sendRequest(config);

        const result = response.data && response.data.data ? response.data.data : [];
        const result_total = response.data.total;

        return Promise.resolve(result);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async print({ commit }, params = {}) {
      try {
        const config = {
          url: "/export-ajuan-komisi",
          method: 'get',
          params,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
          responseType: 'blob',
        }
        const response = await service.sendRequest(config)

        return Promise.resolve(response.data)
      } catch (e) {
        if (e.response && e.response.data) {
          const blob = e.response.data
          const text = await blob.text()
          const errorJson = JSON.parse(text)

          return Promise.reject(errorJson)
        }
        return Promise.reject(e)
      }
    },
  },
};
