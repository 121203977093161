import QueryString from 'qs'
import service from '@/services'

export default {
  namespaced: true,
  state: {
    datas: [],
    status: [],
    total: 0,
  },
  getters: {
    search(state) {
      return id => state.datas.find(bop => bop.id == id)
    },
  },
  mutations: {
    SET_DATA(state, data) {
      state.datas = data
    },
    SET_STATUS(state, data) {
      state.status = data
    },
    SET_TOTAL_DATA(state, data) {
      state.total = data
    },
  },
  actions: {
    async printPembayaran({}, id) {
      try {
        const config = {
          url: `/cetak-pembelian-cabang-bayar/${id}`,
          method: 'get',
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
          responseType: 'blob',
        }
        const response = await service.sendRequest(config)

        return Promise.resolve(response.data)
      } catch (e) {
        if (e.response && e.response.data) {
          const blob = e.response.data
          const text = await blob.text()
          const errorJson = JSON.parse(text)

          return Promise.reject(errorJson)
        }
        return Promise.reject(e)
      }
    },
    async print({}, id) {
      try {
        const config = {
          url: `/cetak-pembelian-cabang/${id}`,
          method: 'get',
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
          responseType: 'blob',
        }
        const response = await service.sendRequest(config)

        return Promise.resolve(response.data)
      } catch (e) {
        if (e.response && e.response.data) {
          const blob = e.response.data
          const text = await blob.text()
          const errorJson = JSON.parse(text)

          return Promise.reject(errorJson)
        }
        return Promise.reject(e)
      }
    },
    async getData({ commit }, params = {}) {
      try {
        const config = {
          url: '/pembelian-cabang',
          method: 'get',
          params,
          paramsSerializer: params => QueryString.stringify(params),
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },

        }
        const response = await service.sendRequest(config)

        const result = response.data && response.data.data ? response.data.data : []
        const total = response.data ? response.data.total : result.length
        commit('SET_TOTAL_DATA', total)
        commit('SET_DATA', result)

        return Promise.resolve(result)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async getDataById({}, params = {}) {
      try {
        const config = {
          url: `/pembelian-cabang/detail/${params.id}`,
          method: 'get',
          params,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },

        }
        const response = await service.sendRequest(config)

        const result = response.data && response.data ? response.data : []
        // commit('SET_DATA', result)

        return Promise.resolve(result)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async getRekening({ commit }, params = {}) {
      try {
        const config = {
          url: '/p-rekening?rekening_utama=1',
          method: 'get',
          params,
          paramsSerializer: params => QueryString.stringify(params),
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },

        }
        const response = await service.sendRequest(config)

        const result = response.data && response.data ? response.data : []

        return Promise.resolve(result)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async saveBukti({}, payload = {}) {
      try {
        const config = {
          url: '/pembelian-cabang/upload-bukti-bayar',
          method: 'post',
          data: payload,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }

        const response = await service.sendRequest(config)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async save({}, payload = {}) {
      try {
        const config = {
          url: '/pembelian-cabang/checkout',
          method: 'post',
          data: payload,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }

        const response = await service.sendRequest(config)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async saveongkir({}, payload = {}) {
      try {
        const config = {
          url: '/pembelian-cabang/approve',
          method: 'post',
          data: payload,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }

        const response = await service.sendRequest(config)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async cancel({}, payload = {}) {
      try {
        const config = {
          url: '/pembelian-cabang/cancel',
          method: 'post',
          data: payload,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }

        const response = await service.sendRequest(config)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async delete({}, payload = {}) {
      try {
        const config = {
          url: '/pembelian-cabang/delete',
          method: 'post',
          data: payload,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }

        const response = await service.sendRequest(config)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async lunas({}, payload = {}) {
      try {
        const config = {
          url: '/pembelian-cabang/tandai-lunas',
          method: 'post',
          data: payload,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }

        const response = await service.sendRequest(config)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async kirim({}, payload = {}) {
      try {
        const config = {
          url: '/pembelian-cabang/change-status',
          method: 'post',
          data: payload,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }

        const response = await service.sendRequest(config)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async terima({}, payload = {}) {
      try {
        const config = {
          url: '/pembelian-cabang/change-status',
          method: 'post',
          data: payload,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }

        const response = await service.sendRequest(config)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
  },
}
